<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to Anypay! 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Please Register
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- username -->
             <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required|min:8"
                >
                  <b-form-input
                    id="name"
                    v-model="data.name"
                    placeholder="Enter Name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <validation-provider
                #default="{ errors }"
                name="plan"
                rules="required"
              >
                <b-form-group
                  label="Plan Name"
                  label-for="plan"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="plan"
                    v-model="plan"
                    placeholder="Select Plan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="planOptions"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- password -->
               <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email|uniqueEmail"
                >
                  <b-form-input
                    id="email"
                    v-model="data.email"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

               <b-form-group
                  label="Password"
                  label-for="Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    :rules="{required:true,min:8,regex:/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9_@./#&+-]+$/}"
                    vid="confirmation"
                  >
                    <password v-model="data.password" :state="errors.length > 0 ? false:null" @feedback="showFeedback" placeholder="Entar Password"/>
                      <small
                            v-if="passworddMessge !='' && !errors[0]"
                            class="text-warning"
                          >{{ passworddMessge }}</small>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                      label="Confirm Password"
                      label-for="Confirm_Password"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Confirm Password"
                        rules="required|confirmed:confirmation"
                      >
                        <b-form-input
                          id="Confirm_Password"
                          v-model="data.password_confirmation"
                          type="password"
                          placeholder="Enter Confrim Password"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                     <b-form-group
                      label="Mobile No."
                      label-for="mobile"
                    >
                      <validation-provider
                        ref="mobileprovider"
                        #default="{ errors }"
                        name="mobile"
                        rules="required|numeric|startWith|min:10|max:12|uniqueMobile"
                      >
                        <cleave
                          id="prefix"
                          v-model="data.mobile"
                          class="form-control"
                          :raw="false"
                          :options="prefix"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter mobile"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                     <b-form-group
                       v-if="otpShow"
                        label-for="otp"
                        label="OTP"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required|min:6|validOtp"
                          name="OTP"
                        >
                          <b-form-input
                            id="otp"
                            v-model="otp"
                            type="number"
                            name="otp"
                            placeholder="Enter OTP"
                          />

                          <small
                            v-if="errors[0]!='OTP is not valid.'"
                            class="text-danger"
                          >{{ errors[0] }}</small>
                          <small
                            v-if="validMessge !='' && errors[0]=='OTP is not valid.'"
                            class="text-danger"
                          >{{ validMessge }}</small>
                          <small
                            v-if="validtrueMessge !=''"
                            class="text-success"
                          >{{ validtrueMessge }}</small>
                           <b-button
                        v-if="stopTrying && !smsOtpValid"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :disabled="loading"
                        variant="outline-success"
                        class=" mr-1 mt-2"
                        @click="requetOTP"
                      >
                        <b-spinner
                          v-show="loading"
                          small
                        />
                        OTP
                      </b-button>
                      <countdown
                        v-if="!stopTrying && showTimer"
                        :time="waitingTime"
                        class="mr-1 mt-2"
                        @end="handleCountdownEnd"
                      >
                        <template slot-scope="props">
                          {{ props.minutes }}:{{ props.seconds }}
                        </template>
                      </countdown>
                        </validation-provider>

                      </b-form-group>
                     

              <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="addUser"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script> 
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver , localize ,extend } from 'vee-validate'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import VueCountdown from '@chenfengyuan/vue-countdown'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Logo from '@core/layouts/components/Logo.vue'

import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,BSpinner,BFormInvalidFeedback,
} from 'bootstrap-vue'
import Password from 'vue-password-strength-meter'
import { $themeConfig } from '@themeConfig'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import SecureLS from 'secure-ls'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { messages } from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'
import Ripple from 'vue-ripple-directive'
import JwtService from '../../common/jwt.service'
import AuthService from '../../services/auth.service'
import UserService from '../../services/user.service'
import en from 'vee-validate/dist/locale/en.json';

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
  })
})

localize({
  en: {

    fields: {
      Name: {
        min: 'Please insert your real name'
      },
      Password: {
        regex: 'Your password must contain at least one lowercase letter and at least one number digit '
      },
    }
  }
});
localize('en', en);

export default {
  components: {
    Logo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BSpinner,
    BForm,
    BCardText,
    BCardTitle,
    Cleave,
    vSelect,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    countdown: VueCountdown,
    ValidationObserver,
    BFormInvalidFeedback,
    Password,
  },
  mixins: [togglePasswordVisibility],
   directives: {
    Ripple,
  },
  data() {
    return {
      stateOptions: [],
      bankOptions: [],
      otp: '',
      stopTrying: 1,
      waitingTime: 0,
      showTimer: 0,
      errorShow: false,
      loading: false,
      smsOtpValid: false,
      validMessge: '',
      validtrueMessge: '',
      otpShow: false,
      success: false,
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      planOptions: [],
      countryOptions: [],
      configdateTimePicker: {
        dateFormat: 'Y-m-d',
      },
      showIsCompany: false,
      plan:'',
      passworddMessge:'',
      required,
      email,
      prefix: {
        prefix: '60',
        onValueChanged:this.isMobileValid
      },

      mount: false,
      data: {
        spid: JwtService.getUser().loginid,
        firm_reg_no: '',
        firm_name: '',
      },
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    const ls = new SecureLS({
      encodingType: 'aes',
      isCompression: false,
    })
    if(this.$route.query.message != '' && this.$route.query.message !== undefined){
       this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: this.$route.query.message,
                icon:'',
                variant: 'danger',
              },
            })
    this.$router.push(this.$route.path)
    }
    const isUniqueMobile = value => UserService.validatePhone({ mobile: value, id: ls.get('edit_user_id') }).then(
      response => {
        this.otpShow = response.mobile_valid
        this.otp = ''
        this.stopTrying = true
        this.success = false
        return {
          valid: response.mobile_valid,
          data: {
            message: response.mobile_message,
          },
        }
      },
      // eslint-disable-next-line no-unused-vars
      error => {},
    )

     const isuniqueEmail = value => UserService.validateEmail({ email: value }).then(
      response => {
        this.otp = ''
        this.stopTrying = true
        this.success = false
        return {
          valid: response.email_valid,
          message: response.email_message,
          data: {
            message: response.email_message,
          },
        }
      },
      // eslint-disable-next-line no-unused-vars
      error => {},
    )
    
    const validateOtp = value => UserService.vaildSmsOTP({ mobile: this.data.mobile, code: value, secureWord: ls.get('secureWord') }).then(
      response => {
        if (response.success) {
          this.otp = value
          this.success = true
          this.showTimer = false
          this.stopTrying = false
          this.smsOtpValid = true
          this.validtrueMessge = response.message
        } else {
          this.validMessge = response.message
          this.smsOtpValid = false
          this.stopTrying = true
        }
        return {
          valid: response.success,
          data: {
            message: response.message,
          },
        }
      },
      error => {
        this.success = false
        this.validMessge = error.response.data.message
        this.stopTrying = error.response.data.data.stopTrying
        return {
          valid: false,
          data: {
            message: error.response.data.message,
          },
        }
      },
    )
    extend('uniqueMobile', {
      validate: isUniqueMobile,
      message: 'Mobile number already exits',
    }, {
      immediate: false,
    })
    extend('startWith', {
      validate: value => !(this.strncmp(value, '60', 2) !== 0 && this.strncmp(value, '01', 2) !== 0),
      message: 'This mobile must start with 60 or 01',
    })

    extend('uniqueEmail', {
      validate: isuniqueEmail,
      message: 'Email Id already exist',
    }, {
      immediate: false,
    })

    extend('validOtp', {
      validate: validateOtp,
      message: '',
    }, {
      immediate: false,
    })

    extend('scorecheck', {
      validate: value => this.showScore(),
      message: 'please set hard password',
    })

    this.getRegisterationFromInfo()
  },
  methods: {
    showScore(score){
       if(score<=2){
        return false
       }else{
        return true
       }
    },
    showFeedback({suggestions, warning}){
       if(warning && suggestions){
        this.passworddMessge = '⚠'+warning+'. please '+suggestions
      }else{
         this.passworddMessge = ''
      }
    },
    strncmp(str1, str2, n) {
      // eslint-disable-next-line no-param-reassign
      str1 = str1.substring(0, n)
      // eslint-disable-next-line no-param-reassign
      str2 = str2.substring(0, n)
      // eslint-disable-next-line no-nested-ternary
      return ((str1 === str2) ? 0 : ((str1 > str2) ? 1 : -1))
    },
    isMobileValid() {
      const isValid = this.$refs.mobileprovider.validate()
      this.otpShow = !!isValid.valid
    },
    handleCountdownEnd() {
      this.stopTrying = 1
      this.showTimer = 0
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.registerForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getRegisterationFromInfo() {
      AuthService.registerationInfoGet().then(
        response => {
          const self = this
          // eslint-disable-next-line array-callback-return
          response.userPlan.map(value => {
            self.planOptions.push(
              { value: value.amount, label: value.plan_name },
            )
          })
        },
        // eslint-disable-next-line no-unused-vars
        error => {
        },
      )
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async  requetOTP() {
      const isValid = await this.$refs.mobileprovider.validate()
      if (isValid.valid) {
        this.loading = true
        UserService.requestOTP({ mobile: this.data.mobile }).then(
          response => {
            this.error = false
            const ls = new SecureLS({
              encodingType: 'aes',
              isCompression: false,
            })
            ls.set('secureWord', response.secureWord)
            ls.set('stopTrying', response.stopTrying)
            this.stopTrying = response.stopTrying
            this.showTimer = 1
            this.waitingTime = response.waitingTime * 1000
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OTP',
                text: response.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          },
          error => {
            this.loading = false
            const self = this
            if (error.response.errors !== undefined) {
              Object.entries(error.response.data.errors).map((value, key) => {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    text: value[1][0],
                    icon: 'CheckIcon',
                    variant: 'danger',
                  },
                })
              })
            } else {
              this.errorShow = true
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: error.response.data.message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
              this.message = error.response.data.message
            }
          },
        )
      }
    },
    addUser() {
      this.data.plan = this.plan.value
      AuthService.registerUser(this.data).then(
        response => {
          window.location.replace(response.url);
        },
        error => {
          const self = this
          if (error.response.data.errors !== undefined) {
            // eslint-disable-next-line array-callback-return
            Object.entries(error.response.data.errors).map(value => {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: value[1][0],
                  icon: 'AddIcon',
                  variant: 'danger',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: error.response.data.message,
                icon: 'AddIcon',
                variant: 'danger',
              },
            })
          }
        },
      )
    },
    mounted() {
      this.mount = true
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
