var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('logo')],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Welcome to Anypay! 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Please Register ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Enter Name","state":errors.length > 0 ? false:null},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"plan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Plan Name","label-for":"plan","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"plan","placeholder":"Select Plan","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.planOptions},model:{value:(_vm.plan),callback:function ($$v) {_vm.plan=$$v},expression:"plan"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email|uniqueEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false:null,"placeholder":"Enter email"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":{required:true,min:8,regex:/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9_@./#&+-]+$/},"vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('password',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Entar Password"},on:{"feedback":_vm.showFeedback},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}}),(_vm.passworddMessge !='' && !errors[0])?_c('small',{staticClass:"text-warning"},[_vm._v(_vm._s(_vm.passworddMessge))]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"Confirm_Password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Confirm_Password","type":"password","placeholder":"Enter Confrim Password","state":errors.length > 0 ? false:null},model:{value:(_vm.data.password_confirmation),callback:function ($$v) {_vm.$set(_vm.data, "password_confirmation", $$v)},expression:"data.password_confirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Mobile No.","label-for":"mobile"}},[_c('validation-provider',{ref:"mobileprovider",attrs:{"name":"mobile","rules":"required|numeric|startWith|min:10|max:12|uniqueMobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"prefix","raw":false,"options":_vm.prefix,"state":errors.length > 0 ? false:null,"placeholder":"Enter mobile"},model:{value:(_vm.data.mobile),callback:function ($$v) {_vm.$set(_vm.data, "mobile", $$v)},expression:"data.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.otpShow)?_c('b-form-group',{attrs:{"label-for":"otp","label":"OTP"}},[_c('validation-provider',{attrs:{"rules":"required|min:6|validOtp","name":"OTP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"otp","type":"number","name":"otp","placeholder":"Enter OTP"},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),(errors[0]!='OTP is not valid.')?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(_vm.validMessge !='' && errors[0]=='OTP is not valid.')?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.validMessge))]):_vm._e(),(_vm.validtrueMessge !='')?_c('small',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.validtrueMessge))]):_vm._e(),(_vm.stopTrying && !_vm.smsOtpValid)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:" mr-1 mt-2",attrs:{"disabled":_vm.loading,"variant":"outline-success"},on:{"click":_vm.requetOTP}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"small":""}}),_vm._v(" OTP ")],1):_vm._e(),(!_vm.stopTrying && _vm.showTimer)?_c('countdown',{staticClass:"mr-1 mt-2",attrs:{"time":_vm.waitingTime},on:{"end":_vm.handleCountdownEnd},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.minutes)+":"+_vm._s(props.seconds)+" ")]}}],null,true)}):_vm._e()]}}],null,false,3051114268)})],1):_vm._e(),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addUser($event)}}},[_vm._v(" Sign up ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }